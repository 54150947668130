import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  private baseUrl = 'user/user'

  public getRealmEmailAddresses(
    search: string,
    pageIndex = 0,
    offset = 5,
  ): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.baseUrl}/emails`, {
        params: Object.fromEntries([
          ['page', `${pageIndex}`],
          ['size', `${offset}`],
          ['search', `${search}`],
        ]),
      })
      .pipe(catchError(_ => of([])))
  }
}
