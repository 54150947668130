import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { ChartPeriod } from '../../../../jobsites-management/jobsite-summary/jobsite-productivity/models/productivity/productivity.model'
import { ProgressCategory } from 'app/jobsites-management/jobsite-summary/models/progress/progress-category.enum'
import { TranslateService } from '@ngx-translate/core'
import { UserPreferencesDto } from 'app/shared/remote-services/dtos/userPreferences.dto'

@Component({
  selector: 'jobsite-follow-graph-header',
  template: `
    <mat-radio-group
      color="primary"
      [ngModel]="progressCategory"
      (change)="updateProgressCategoryEvent.emit($event.value)"
    >
      <mat-radio-button
        *ngFor="let category of categoriesToChooseFrom"
        [value]="category"
        >{{
          getCategoryHeaderTranslation(category, userPreferences)
        }}</mat-radio-button
      >
    </mat-radio-group>
    <div class="header-center">
      <button
        *ngIf="showProgressButton"
        mat-icon-button
        class="planned-button"
        disableRipple
        [disabled]="!enableProgressBtn"
        (click)="toggleEditPlannedProgress()"
        matTooltip="{{
          'JOBSITE_MANAGEMENT.FOLLOW_UP.EDIT_PLANNED_PROGRESS' | translate
        }}"
      >
        <mat-icon>addchart</mat-icon>
      </button>

      <span class="chart-title">
        {{ chartTitle }}
      </span>

      <button
        *ngIf="showPeriodicityMenu"
        mat-icon-button
        disableRipple
        [matMenuTriggerFor]="periodicityMenu"
      >
        <mat-icon> calendar_today</mat-icon>
      </button>
      <button
        *ngIf="enableExport"
        mat-icon-button
        disableRipple
        matTooltip="{{ 'MAP_BUTTONS.EXPORT_CSV' | translate }}"
        (click)="exportCSV()"
      >
        <mat-icon fontSet="fa" fontIcon="fa-download"></mat-icon>
      </button>
      <button
        *ngIf="enableAdminExport"
        mat-icon-button
        disableRipple
        matTooltip="{{ 'MAP_BUTTONS.ADMIN_EXPORT_CSV' | translate }}"
        (click)="exportAdminCSV()"
      >
        <mat-icon fontSet="fa" fontIcon="fa-download"></mat-icon>
      </button>
      <mat-menu #periodicityMenu>
        <button mat-menu-item (click)="updateProgressPeriodEvent.emit('DAILY')">
          {{ 'PROJECT_MANAGEMENT.PRODUCTIVITY.DAILY' | translate }}
        </button>
        <button
          mat-menu-item
          (click)="updateProgressPeriodEvent.emit('WEEKLY')"
        >
          {{ 'PROJECT_MANAGEMENT.PRODUCTIVITY.WEEKLY' | translate }}
        </button>
        <button
          mat-menu-item
          (click)="updateProgressPeriodEvent.emit('MONTHLY')"
        >
          {{ 'PROJECT_MANAGEMENT.PRODUCTIVITY.MONTHLY' | translate }}
        </button>
      </mat-menu>
    </div>
  `,
  styles: [
    `
      :host {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        align-content: center;
      }

      .header-center {
        display: flex;
        justify-content: center;
      }

      .mat-radio-group {
        display: flex;
        align-items: center;
        gap: 5%;
      }

      .mat-radio-button {
        display: flex;
      }

      .planned-button {
        z-index: 20;
      }

      .chart-title {
        margin: auto 25px;
        font-size: 20px;
        white-space: nowrap;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsiteFollowGraphHeaderComponent implements OnInit {
  @Input() chartTitle: string
  @Input() enableProgressBtn: boolean
  @Input() userPreferences: UserPreferencesDto
  @Input() category: ProgressCategory
  @Input() categoriesToChooseFrom: ProgressCategory[]
  @Input() enableExport: boolean
  @Input() enableAdminExport = false
  @Input() showPeriodicityMenu = true
  @Input() showProgressButton = true

  progressCategory: ProgressCategory

  @Output() updateProgressPeriodEvent = new EventEmitter<ChartPeriod>()
  @Output() updateProgressCategoryEvent = new EventEmitter<ProgressCategory>()
  @Output() openEditPlanedDialogEvent = new EventEmitter<null>()
  @Output() exportProductivityChartEvent = new EventEmitter<null>()
  @Output() adminExportProductivityChartEvent = new EventEmitter<null>()

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.progressCategory = this.category ?? ProgressCategory.COUNT
  }

  getCategoryHeaderTranslation(
    category: ProgressCategory,
    userPreferences: UserPreferencesDto,
  ) {
    switch (category) {
      case ProgressCategory.AREA_SIMPLE:
        return this.translateService.instant(
          'JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_' + category,
          {
            unit: userPreferences.unitSystem === 'imperial' ? 'in²' : 'm²',
          },
        )
      case ProgressCategory.LENGTH:
        return this.translateService.instant(
          `JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_${category}_${userPreferences.unitSystem.toUpperCase()}`,
        )
      default:
        return this.translateService.instant(
          'JOBSITE_MANAGEMENT.FOLLOW_UP.CHOICE_' + category,
        )
    }
  }

  toggleEditPlannedProgress(): void {
    if (this.enableProgressBtn) {
      this.openEditPlanedDialogEvent.emit()
    }
  }

  exportCSV(): void {
    if (this.enableExport) {
      this.exportProductivityChartEvent.emit()
    }
  }
  exportAdminCSV(): void {
    if (this.enableAdminExport) {
      this.adminExportProductivityChartEvent.emit()
    }
  }
}
