// Build information, automatically generated by `ng-info`
const build = {
    version: "22.4.0-SNAPSHOT",
    timestamp: "Thu Apr 03 2025 10:48:43 GMT+0200 (Central European Summer Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "7c1d5a",
        fullHash: "7c1d5a1aab086fc9b6bff3b398152beb309f00b4"
    }
};

export default build;