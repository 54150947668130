/*
 * Generated type guards for "export-pdf-deviation-options.model.ts".
 * WARNING: Do not manually change this file.
 */
import { isDeviationReportDisplayType } from '../../../constants/deviation-report-display.enum.type.guard'
import { ExportPdfDeviationOptionsForm } from './export-pdf-deviation-options.model'

export function isExportPdfDeviationOptionsForm(
  obj: unknown,
): obj is ExportPdfDeviationOptionsForm {
  const typedObj = obj as ExportPdfDeviationOptionsForm
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (isDeviationReportDisplayType(typedObj['displayType']) as boolean) &&
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (typedObj['toleranceLR'] === null ||
      typeof typedObj['toleranceLR'] === 'number') &&
    (typedObj['toleranceFB'] === null ||
      typeof typedObj['toleranceFB'] === 'number') &&
    (typedObj['chartDisplayMaxDepth'] === null ||
      typeof typedObj['chartDisplayMaxDepth'] === 'number') &&
    (typeof typedObj['chartDisplayMaxDepthUnit'] === 'undefined' ||
      typedObj['chartDisplayMaxDepthUnit'] === null ||
      typeof typedObj['chartDisplayMaxDepthUnit'] === 'string')
  )
}
