import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { DeviationReportDisplayType } from '../../../constants/deviation-report-display.enum'

@Component({
  selector: 'hf-deviation-report-display-setting',
  template: `
    <div
      class="flex-align--center flex-gap-15px"
      [ngClass]="optionsDirection === 'row' ? 'flex' : 'flex-layout-column'"
    >
      <label id="radio-group-label">
        {{
          'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.DISPLAY_SETTING_LABEL'
            | translate
        }}
      </label>
      <mat-radio-group
        [formControl]="optionFormControl"
        [ngClass]="optionsDirection === 'row' ? 'flex' : 'flex-layout-column'"
        [class.radio-group]="optionsDirection === 'column'"
        class="flex-gap-10px"
        aria-labelledby="radio-group-label"
      >
        <mat-radio-button
          *ngFor="let option of options"
          [value]="option"
          color="primary"
        >
          {{
            'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.' + option
              | translate
          }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  `,
  styles: [
    `
      .radio-group {
        margin: 15px 0;
      }

      ::ng-deep .mat-radio-label-content {
        font-weight: normal;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HfDeviationReportDisplaySettingComponent {
  @Input() optionFormControl: FormControl<DeviationReportDisplayType>
  @Input() optionsDirection: 'column' | 'row' = 'column'

  public options: DeviationReportDisplayType[] = [
    DeviationReportDisplayType.FULL_TOLERANCE_CONE,
    DeviationReportDisplayType.DETAILED_DEVIATION_CURVE,
  ]
}
