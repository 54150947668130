import * as Guards from './constants.enum.type.guard'

/** @see {isHexCode} ts-auto-guard:type-guard */
export enum HexCode {
  black = 0x000000,
  red = 0xff0000,
  white = 0xffffff,
  sceneBackground = 0xf0f8ff,
  clippingPlan = 0xeeddcc,
  AmbientLight = 0xcccccc,
  GridColor = 0x555555,
}

/** @see {isMeshTypes} ts-auto-guard:type-guard */
export enum MeshTypes {
  pile = 'PILE',
  soillibSurvey = 'SOILLIB_SURVEY',
  soillibSurveyLayer = 'SOILLIB_SURVEY_LAYER',
  dWall = 'DWALL',
}

/** @see {isSceneProfileTypes} ts-auto-guard:type-guard */
export enum SceneProfileTypes {
  default = 'DEFAULT_PROFILE',
  pileCenter = 'PILE_CENTER',
  synchro2D = 'SYNCHRONIZE_2D',
}

export { Guards }
