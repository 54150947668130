<h2 mat-dialog-title>
  {{ 'PLATFORM_DISRUPTIONS.TITLE' | translate }}
</h2>

<div mat-dialog-content>
  <form [formGroup]="form" (submit)="addDisruption()" class="form-container">
    <mat-form-field class="input-message-en">
      <input matInput formControlName="messageEn" />
      <mat-label>{{
        'PLATFORM_DISRUPTIONS.COLUMNS.messageEn' | translate
      }}</mat-label>
    </mat-form-field>

    <mat-form-field class="input-message-es">
      <input matInput formControlName="messageEs" />
      <mat-label>{{
        'PLATFORM_DISRUPTIONS.COLUMNS.messageEs' | translate
      }}</mat-label>
    </mat-form-field>

    <mat-form-field class="input-message-fr">
      <input matInput formControlName="messageFr" />
      <mat-label>{{
        'PLATFORM_DISRUPTIONS.COLUMNS.messageFr' | translate
      }}</mat-label>
    </mat-form-field>

    <div class="save-button">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="!form.valid"
        [ngClass]="{ disabled: !form.valid }"
      >
        {{ 'GENERAL.ADD' | translate }}
      </button>
    </div>
  </form>

  <div
    *ngIf="displayLoader; else dialogContent"
    class="spinner flex flex-align-center--center"
  >
    <mat-spinner color="primary"></mat-spinner>
  </div>

  <ng-template #dialogContent>
    <div class="dialog-content">
      <table mat-table [dataSource]="disruptions">
        <ng-container
          *ngFor="let column of displayedColumns"
          [matColumnDef]="column"
        >
          <th mat-header-cell *matHeaderCellDef>
            {{ 'PLATFORM_DISRUPTIONS.COLUMNS.' + column | translate }}
          </th>

          <ng-container
            *ngIf="
              column === 'startDate' || column === 'endDate';
              else defaultCell
            "
          >
            <td mat-cell *matCellDef="let row">
              <button
                *ngIf="
                  column === 'endDate' && row[column] == null;
                  else dateCell
                "
                mat-raised-button
                color="warn"
                type="button"
                (click)="closeDisruption.emit(row.id)"
              >
                {{ 'PLATFORM_DISRUPTIONS.COLUMNS.CLOSE_BUTTON' | translate }}
              </button>
              <ng-template #dateCell>
                {{ row[column] | localizedDateTime }}
              </ng-template>
            </td>
          </ng-container>
          <ng-template #defaultCell>
            <td mat-cell *matCellDef="let row">
              {{ row[column] }}
            </td>
          </ng-template>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </ng-template>
</div>
<mat-dialog-actions align="end">
  <button mat-raised-button type="button" (click)="closeDialog.emit()">
    {{ 'GENERAL.CLOSE' | translate }}
  </button>
</mat-dialog-actions>
