import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms'
import { DeviationReportDisplayType } from '../../../constants/deviation-report-display.enum'
import { HFDeviationExportParams } from '../../../../jobsites-management/jobsite-summary/components/export-pdf-dialog/export-pdf-dialog.model'
import { TypeToFormGroup } from '../../../models/utils.type'
import { ExportPdfDeviationOptionsForm } from './export-pdf-deviation-options.model'
import { isExportPdfDeviationOptionsForm } from './export-pdf-deviation-options.model.type.guard'
import { Subscription } from 'rxjs'
import { ContractualTolerances } from '../../../models/contractual-tolerances.model'
import { MaxDepthDeviationReport } from '../../../models/max-depth-deviation-report.model'

@Component({
  selector: 'export-pdf-deviation-option-form',
  template: `
    <div class="flex-layout-column flex-gap-10px">
      <div class="flex-layout-column">
        <hf-deviation-report-display-setting
          [optionFormControl]="form.controls.displayType"
          optionsDirection="row"
        ></hf-deviation-report-display-setting>
      </div>
      <div class="flex">
        <mat-form-field class="flex-1 flex">
          <input
            autofocus
            matInput
            (click)="$event.stopPropagation()"
            type="number"
            min="0"
            step="0.1"
            autocomplete="off"
            [formControl]="form.controls.toleranceLR"
          />
          <mat-label>{{
            'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.TOLERANCE_LR'
              | translate
          }}</mat-label>
          <mat-error *ngIf="form.controls.toleranceLR?.hasError('max')">
            {{ 'GENERAL.FIELD_MAX_VALUE' | translate : { value: 5 } }}
          </mat-error>
          <mat-error *ngIf="form.controls.toleranceLR?.hasError('min')">
            {{ 'GENERAL.FIELD_MIN_VALUE' | translate : { value: 0 } }}
          </mat-error>
        </mat-form-field>
        <div class="flex flex-align-center--center">
          <zl-helper
            [popoverText]="
              'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.TOLERANCE_OVERRIDE_HELPER'
                | translate
            "
          ></zl-helper>
        </div>
      </div>

      <div class="flex">
        <mat-form-field class="flex-1 flex">
          <input
            matInput
            (click)="$event.stopPropagation()"
            type="number"
            min="0"
            step="0.1"
            autocomplete="off"
            [formControl]="form.controls.toleranceFB"
          />
          <mat-label>{{
            'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.TOLERANCE_FB'
              | translate
          }}</mat-label>
          <mat-error *ngIf="form.controls.toleranceFB?.hasError('max')">
            {{ 'GENERAL.FIELD_MAX_VALUE' | translate : { value: 5 } }}
          </mat-error>
          <mat-error *ngIf="form.controls.toleranceFB?.hasError('min')">
            {{ 'GENERAL.FIELD_MIN_VALUE' | translate : { value: 0 } }}
          </mat-error>
        </mat-form-field>
        <div class="flex flex-align-center--center">
          <zl-helper
            [popoverText]="
              'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.TOLERANCE_OVERRIDE_HELPER'
                | translate
            "
          ></zl-helper>
        </div>
      </div>

      <div class="full-form-container-size flex flex-gap-10px">
        <mat-form-field class="flex-1">
          <input
            matInput
            (click)="$event.stopPropagation()"
            type="number"
            min="0"
            step="0.1"
            autocomplete="off"
            [formControl]="form.controls.chartDisplayMaxDepth"
          />
          <mat-label>{{
            'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.CHART_DISPLAY_MAX_DEPTH'
              | translate
          }}</mat-label>
          <mat-error
            *ngIf="form.controls.chartDisplayMaxDepth?.hasError('min')"
          >
            {{ 'GENERAL.FIELD_MIN_VALUE' | translate : { value: 0 } }}
          </mat-error>
        </mat-form-field>

        <div class="half-form-field-size flex deviation-max-depth">
          <mat-form-field class="flex-grow">
            <input
              matInput
              autocomplete="off"
              [formControl]="form.controls.chartDisplayMaxDepthUnit"
              required
            />
            <mat-label>{{
              'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.CHART_DISPLAY_MAX_DEPTH_UNIT'
                | translate
            }}</mat-label>
          </mat-form-field>

          <zl-helper
            [popoverText]="
              'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.CHART_DISPLAY_MAX_DEPTH_HELPER'
                | translate
            "
          ></zl-helper>
        </div>
      </div>
    </div>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportPdfDeviationOptionsFormComponent
  implements OnInit, OnDestroy
{
  @Output() valueChange = new EventEmitter<HFDeviationExportParams | null>()
  @Input() contractualTolerances: ContractualTolerances | null
  public form: TypeToFormGroup<ExportPdfDeviationOptionsForm>
  @Input() maxDepthDeviationReport: MaxDepthDeviationReport | null

  sub: Subscription[] = []

  ngOnInit(): void {
    this.form = new FormGroup({
      displayType: new FormControl<DeviationReportDisplayType>(
        DeviationReportDisplayType.FULL_TOLERANCE_CONE,
        Validators.required,
      ),
      toleranceLR: new FormControl<number>(
        this.contractualTolerances?.contractualToleranceLR,
        [Validators.min(0.01), Validators.max(4.99)],
      ),
      toleranceFB: new FormControl<number>(
        this.contractualTolerances?.contractualToleranceFB,
        [Validators.min(0.01), Validators.max(4.99)],
      ),
      chartDisplayMaxDepth: new FormControl<number>(
        this.maxDepthDeviationReport?.maxDepthDeviationReport,
        Validators.min(0.01),
      ),
      chartDisplayMaxDepthUnit: new FormControl<string>(
        {
          value: this.maxDepthDeviationReport?.maxDepthDeviationReportUnit,
          disabled: true,
        },
        this.maxDepthValidator,
      ),
    })
    this.sub.push(
      this.form.controls.chartDisplayMaxDepth.valueChanges.subscribe(v => {
        if (v != null) {
          this.form.controls.chartDisplayMaxDepthUnit.enable()
        } else {
          this.form.controls.chartDisplayMaxDepthUnit.disable()
        }
      }),
    )
    this.sub.push(
      this.form.controls.displayType.valueChanges.subscribe(v => {
        if (v === DeviationReportDisplayType.FULL_TOLERANCE_CONE) {
          this.form.controls.chartDisplayMaxDepth.enable()
          this.form.controls.chartDisplayMaxDepth.updateValueAndValidity()
        } else {
          this.form.controls.chartDisplayMaxDepth.disable()
          this.form.controls.chartDisplayMaxDepthUnit.disable()
        }
      }),
    )
    this.sub.push(this.form.valueChanges.subscribe(_ => this.submit()))
    this.form.controls.chartDisplayMaxDepth.updateValueAndValidity()
    this.submit()
  }

  ngOnDestroy(): void {
    this.sub.forEach(s => s.unsubscribe())
  }

  submit(): void {
    if (
      this.form.valid &&
      isExportPdfDeviationOptionsForm(this.form.getRawValue())
    ) {
      this.valueChange.emit({
        ...this.form.getRawValue(),
        fullToleranceCone:
          this.form.getRawValue().displayType ===
          DeviationReportDisplayType.FULL_TOLERANCE_CONE,
      })
    } else {
      this.valueChange.emit(null)
    }
  }

  maxDepthValidator: ValidatorFn = (
    control: FormControl<string | null>,
  ): ValidationErrors | null => {
    const maxDepth = (
      control.parent as TypeToFormGroup<ExportPdfDeviationOptionsForm>
    )?.controls?.chartDisplayMaxDepth
    if (maxDepth != null) {
      return Validators.required(control)
    }
    return null
  }
}
