import { CanActivateFn } from '@angular/router'
import { select } from '@ngrx/store'
import { map, withLatestFrom } from 'rxjs/operators'
import { AbstractLoadableGuardBuilder } from '../../shared/utils/abstract-loadable.guard'
import { getRouterUrl } from '../store/router/router.selectors'
import { getApplications, getLoaded, UserActions } from '../store/user'
import { State } from '../store'

export const PermissionsGuard: CanActivateFn =
  new AbstractLoadableGuardBuilder<State>(
    getLoaded,
    UserActions.loadUser,
    false,
    {
      postCheck: (store, router) => {
        return store.pipe(
          select(getRouterUrl),
          withLatestFrom(store.pipe(select(getApplications))),
          map(([url, applications]) => {
            // If the user can access only one application without technique choice or with only one technique
            if (
              !url.startsWith('/download') &&
              applications.length === 1 &&
              (applications[0].techniques.length === 0 ||
                applications[0].techniques.length === 1)
            ) {
              let expectedUrl = `/${applications[0].url}`
              if (url.startsWith(`${expectedUrl}/settings`)) {
                return true
              }
              if (applications[0].techniques.length === 1) {
                expectedUrl += `/${applications[0].techniques[0]}`
              }
              if (url.startsWith(expectedUrl)) {
                return true
              }
              const urlTreeCommands = [applications[0].url]
              if (applications[0].techniques.length === 1) {
                urlTreeCommands.push(applications[0].techniques[0])
              }
              return router.createUrlTree(urlTreeCommands)
            }

            // Otherwise we need to check if the user can access to the current url
            if (url !== '' && url !== '/' && !url.startsWith('/download')) {
              for (const application of applications) {
                if (url.startsWith(`/${application.url}/settings`)) {
                  return true
                } else if (url.startsWith(`/${application.url}`)) {
                  if (application.techniques.length > 0) {
                    for (const technique of application.techniques) {
                      if (url.startsWith(`/${application.url}/${technique}`)) {
                        return true
                      }
                    }
                  } else {
                    return true
                  }
                }
              }
              return router.createUrlTree(['/'])
            }
            return true
          }),
        )
      },
    },
  ).canActivate()
