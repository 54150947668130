import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, NgModule } from '@angular/core'
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app.routing'

import { AppComponent } from './app.component'
import { HeaderComponent } from './header/header.component'

import { KeycloakModule } from 'app/keycloak/keycloak.module'
import { MatSliderModule } from '@angular/material/slider'
import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import { ActionReducer, StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { CoreModule } from './core/core.module'
import { environment } from '../environments/environment'
import { CleanerInterceptor, LoaderInterceptor } from './core/interceptors'
import { UploaderFilesModule } from './widgets/uploader-files/uploader-files.module'
import { TypedAction } from 'app/shared/utils/redux/loadable/loadable.actions'
import * as Sentry from '@sentry/angular'
import { SsdSoillibLibModule } from '@sde-ild/ssd-soillib-lib'
import { FooterComponent } from './footer/footer.component'
import { ClipboardModule } from '@angular/cdk/clipboard'
import { TranslationModule } from './core/i18n/translation.module'
import { Router } from '@angular/router'
import { remoteServices } from './shared/remote-services'
import { PlatformDisruptionsComponent } from './platform-disruptions/top-message/platform-disruptions.component'
import { ManageDisruptionsDialogComponent } from './platform-disruptions/admin-modal/manage-disruptions-dialog.component'
import { ManageDisruptionsDialogContainerComponent } from './platform-disruptions/admin-modal/manage-disruptions-dialog-container.component'
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker'
import { NgxMatomoRouterModule } from '@ngx-matomo/router'
import { TrackDataInterceptor } from './core/interceptors/track-data.interceptor'

Sentry.init({
  dsn: environment.sentryDsn,
  environment: environment.platform,
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.3,
})

function sentryLogger(reducer: ActionReducer<unknown>): ActionReducer<unknown> {
  return (state = {}, action: TypedAction<unknown>) => {
    Sentry.addBreadcrumb({
      category: 'ngrx',
      message: action.type,
      data: action.payload,
      level: 'info',
    })
    return reducer(state, action)
  }
}

registerLocaleData(localeFr)

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ManageDisruptionsDialogComponent,
    ManageDisruptionsDialogContainerComponent,
    PlatformDisruptionsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    TranslationModule,
    KeycloakModule,
    MatSliderModule, // needed in root module for slider!
    StoreModule.forRoot(
      {},
      {
        metaReducers: [sentryLogger],
        runtimeChecks: {
          strictStateImmutability: !environment.production,
          strictActionImmutability: !environment.production,
          strictStateSerializability: false,
          strictActionSerializability: false,
        },
      },
    ),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'core.router',
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          name: 'NgRx Planning DevTools',
        })
      : [],
    CoreModule.forRoot(),
    AppRoutingModule,
    UploaderFilesModule,
    SsdSoillibLibModule,
    ClipboardModule,
    NgxMatomoTrackerModule.forRoot({
      trackerUrl: environment.matomo.trackerUrl,
      siteId: environment.matomo.siteId,
    }),
    NgxMatomoRouterModule.forRoot({
      interceptors: [TrackDataInterceptor],
    }),
  ],

  providers: [
    ...remoteServices,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CleanerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => null,
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
