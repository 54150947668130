import { State as CoreState } from 'app/core/store/state'
import { AbstractLoadableGuardBuilder } from '../../shared/utils/abstract-loadable.guard'
import { CompaniesActions } from '../store/companies/companies.actions'
import { companiesSelectors } from '../store/companies/companies.selectors'
import { CanActivateFn } from '@angular/router'

export const CompaniesGuardFn: CanActivateFn =
  new AbstractLoadableGuardBuilder<CoreState>(
    companiesSelectors.getLoaded,
    CompaniesActions.companies,
  ).canActivate()
