import { AbstractLoadableGuardBuilder } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../store'
import { platformDisruptionMessageSelector } from '../store/platform-disruptions/platform-disruptions.selectors'
import { PlatformDisruptionsActions } from '../store/platform-disruptions/platform-disruptions.actions'
import { CanActivateFn } from '@angular/router'

export const PlatformDisruptionsGuard: CanActivateFn =
  new AbstractLoadableGuardBuilder<State>(
    platformDisruptionMessageSelector.getLoaded,
    PlatformDisruptionsActions.loadPlatformDisruptionMessage,
  ).canActivate()
