/*
 * Generated type guards for "deviation-report-display.enum.ts".
 * WARNING: Do not manually change this file.
 */
import { DeviationReportDisplayType } from './deviation-report-display.enum'

export function isDeviationReportDisplayType(
  obj: unknown,
): obj is DeviationReportDisplayType {
  const typedObj = obj as DeviationReportDisplayType
  return (
    typedObj === DeviationReportDisplayType.FULL_TOLERANCE_CONE ||
    typedObj === DeviationReportDisplayType.DETAILED_DEVIATION_CURVE
  )
}
