import { ProgressDto } from '../../../../../jobsites-management/jobsite-summary/models/progress/progress.model'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Moment } from 'moment'

export class PlannedProgressRow {
  private readonly originalData: ProgressDto
  public form: FormGroup<{
    date: FormControl<Moment>
    count: FormControl<number>
  }>
  public isEditing = false
  public isNew = false

  constructor(progress: ProgressDto, isNew = false) {
    this.originalData = { ...progress }
    this.form = new FormGroup({
      date: new FormControl(progress.date, Validators.required),
      count: new FormControl(progress.count, [
        Validators.required,
        Validators.min(0),
      ]),
    })
    this.isEditing = isNew
    this.isNew = isNew
    isNew ? this.form.enable() : this.form.disable()
  }

  public static from(progresses: ProgressDto[]): PlannedProgressRow[] {
    return progresses.map(p => new PlannedProgressRow(p))
  }

  public setEditing(): void {
    this.isEditing = true
    this.form.enable()
  }

  public cancel(): void {
    this.isEditing = false
    this.form.reset({
      date: this.originalData.date,
      count: this.originalData.count,
    })
    this.form.disable()
  }

  public displayFloatLabel(): boolean {
    return this.isEditing
  }

  public getProgressionData(): ProgressDto {
    return {
      ...this.originalData,
      date: this.form.getRawValue().date,
      count: this.form.getRawValue().count,
    }
  }

  public getOriginalForDeletion(): ProgressDto {
    return this.originalData
  }
}
