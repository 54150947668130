import { AbstractLoadableGuardBuilder } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../store'
import { UserActions } from '../store/user/user.actions'
import { userPreferencesSelector } from '../store/user/user.selectors'
import { CanActivateFn } from '@angular/router'

export const UserPreferencesGuard: CanActivateFn =
  new AbstractLoadableGuardBuilder<State>(
    userPreferencesSelector.getLoaded,
    UserActions.loadUserPreferences,
  ).canActivate()
