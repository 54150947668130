import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { CsvService } from '@sde-ild/ssd-csv-lib'
import { lengthUnits } from 'app/shared/utils/unit-converter'
import { Metric } from '../../constants/metric.enum'
import { DEFAULT_CSV_PREFERENCES } from '../../../core/models/companyCsvPreferencesInput.dto'
import { MatTableDataSource } from '@angular/material/table'
import { typedEntries, typedKeys } from '../../utils/typedKeys'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Subscription } from 'rxjs'

export interface CsvData {
  headers: string[]
  data: Record<string, string>[]
}
@Component({
  selector: 'shared-csv-import',
  templateUrl: 'shared-csv-import.component.html',
  styleUrls: ['shared-csv-import.component.scss'],
})
export class SharedCsvImportDialog implements OnInit, OnDestroy {
  @Input() dialogTitle = ''
  @Input() dialogTitleHelpLink: string | null
  @Input() headerFormKeys: Record<string, Metric | null>
  @Input() overwrittenHeaderFormKeys: string[] = []
  @Input() requiredHeaderFormKeys: string[] = []
  @Input() headersWithStringValues: string[] = []
  @Input() headerKeysAutoMapper: Record<string, RegExp>
  @Input() availableUnits: string[] = []
  @Input() showImportSettings = false
  @Input() errors: string[] = []
  @Input() displayTable = true
  @Input() headerTranslationPrefix = ''

  @Input() unitHeaderMap: Record<string, string>

  @Output() importedData = new EventEmitter<{
    data: Record<string, string | null | number>[]
    unit: Record<string, string>
  } | null>()

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
  @ViewChild(MatSort, { static: true }) sort: MatSort

  internalErrors: string[] = []

  csvData: CsvData = {
    data: [],
    headers: [],
  }
  dataSource: MatTableDataSource<Record<string, string | number | null>> | null
  tableHeaders: string[]

  importUnit: Record<string, string> = {}

  subscriptions: Subscription[] = []

  selectedFile: File
  headersForm: FormGroup<Record<string, FormControl<string | string[] | null>>>
  optionsForm: FormGroup<{
    autoNumbering: FormControl<string | null>
    extractZoneIndex: FormControl<number | null>
  }>
  // used for select dropdown in html
  csvSeparator: string = DEFAULT_CSV_PREFERENCES.csvSeparator

  constructor(
    private csvService: CsvService,
    private translate: TranslateService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  ngOnInit(): void {
    this.headersForm = new FormGroup(
      this.getFormGroup(Object.keys(this.headerFormKeys)),
    )

    this.subscriptions.push(
      this.headersForm.valueChanges.subscribe(() => {
        this.apply()
      }),
    )
  }

  private getFormGroup(
    keys: Array<string>,
  ): Record<string, FormControl<string | string[] | null>> {
    return Object.fromEntries(
      keys.map(key => [
        key,
        new FormControl(
          null,
          this.requiredHeaderFormKeys.includes(key)
            ? [Validators.required]
            : [],
        ),
      ]),
    )
  }

  getUnitValue(metric: Metric | null): string {
    return metric !== null ? this.unitHeaderMap[metric] : null
  }

  unitChanged(headerKey: string, unit: (typeof lengthUnits)[number]): void {
    this.importUnit[headerKey] = unit
    this.apply()
  }

  async handleFileSelected(event: Event): Promise<void> {
    const file = (event.target as HTMLInputElement).files[0]
    if (file) {
      this.initData()
      this.selectedFile = file
      await this.parseCsvFile(this.selectedFile, this.csvSeparator)
      this.showImportSettings = true
    }
  }

  private initData(): void {
    this.csvData = null
    this.csvData = {
      data: [],
      headers: [],
    }
    this.dataSource = null
    this.selectedFile = null
    this.optionsForm?.controls?.autoNumbering.setValue(null)
    this.optionsForm?.controls?.extractZoneIndex.setValue(null)
    this.initUnit()
  }

  private initUnit(): void {
    this.importUnit = Object.entries(this.headerFormKeys)
      .filter(([key, value]) => value)
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: this.getUnitValue(value),
        }),
        {},
      )
  }

  private async parseCsvFile(file: File, separator: string): Promise<void> {
    const result = await this.csvService.parseCsvPromise(file, {
      delimiter: separator,
      skipEmptyLines: true,
      header: true,
    })
    this.csvData.headers = result.meta.fields
    this.csvData.data = result.data
    this.preFillHeaders()
    this.apply()
  }

  private setUpDataSource(data: Record<string, string | null | number>[]) {
    if (this.dataSource == null) {
      this.dataSource = new MatTableDataSource()
    }
    this.dataSource.paginator = this.paginator
    this.dataSource.data = data
    this.dataSource.sort = this.sort
    this.dataSource.paginator?.firstPage()
  }

  private preFillHeaders(): void {
    // reset header
    for (const key of Object.keys(this.headerFormKeys)) {
      this.headersForm.get(key)?.reset()
    }
    this.csvData.headers.forEach((header: string) => {
      for (const key of Object.keys(this.headerFormKeys)) {
        const formTitle: RegExp | null = this.headerKeysAutoMapper[key]
        if (formTitle && formTitle.test(header)) {
          this.headersForm.get(key)?.setValue(header)
          break
        }
      }
    })
    this.headersForm.markAllAsTouched()
  }

  async updateCSVSeparator(separator: string): Promise<void> {
    this.csvSeparator = separator
    if (this.selectedFile && separator) {
      await this.parseCsvFile(this.selectedFile, this.csvSeparator)
    }
  }

  public apply(): void {
    this.internalErrors = []
    if (this.headersForm.valid && this.csvData != null) {
      const headerFormValues: Record<string, string | null | string[]> =
        this.headersForm.getRawValue()

      this.tableHeaders = typedKeys(headerFormValues).filter(
        (key: string) => headerFormValues[key],
      )

      const importData = this.csvData.data.map((row, index) =>
        typedEntries(headerFormValues).reduce(
          (
            acc: Record<string, string | null | number>,
            [headerName, csvColumnName],
          ) => {
            return {
              ...acc,
              ...this.mapParsedData(headerName, csvColumnName, row, index),
            }
          },
          {},
        ),
      )

      this.setUpDataSource(importData)
      this.importedData.emit({
        data: importData,
        unit: this.importUnit,
      })
    } else {
      this.importedData.emit(null)
    }
  }

  private mapParsedData(
    headerName: string,
    csvColumnName: string | null | string[],
    row: Record<string, string>,
    index: number,
  ): Record<string, string | number | null> {
    if (!csvColumnName || typeof csvColumnName === 'object') {
      this.internalErrors.push(
        this.translate.instant('SHARED_CSV_IMPORT.ERROR_HEADER', {
          headerName,
        }),
      )
      return { [headerName]: null }
    }

    const res: Record<string, string | number | null> = {}
    if (this.headersWithStringValues.includes(headerName)) {
      res[headerName] = row?.[csvColumnName]?.trim()
    } else {
      // others value must be numeric
      const valueString = row?.[csvColumnName]?.trim()
      let valueFloat: number | null

      if (!valueString) {
        valueFloat = null
      } else if (
        valueString.indexOf(',') >= 0 &&
        valueString.indexOf('.') >= 0
      ) {
        // english notation (10,000.00) to be converted into (10000.00)
        valueFloat = parseFloat(valueString.replace(',', '').replace(' ', ''))
      } else {
        // french notation (10 000,00) to be converted into (10000.00)
        valueFloat = parseFloat(valueString.replace(',', '.').replace(' ', ''))
      }
      res[headerName] = valueFloat

      if (isNaN(valueFloat)) {
        this.internalErrors.push(
          this.translate.instant('SHARED_CSV_IMPORT.ERROR_NUMERIC', {
            index: index + 1,
            columnname: headerName,
          }),
        )
      }
    }

    return res
  }

  openHelpDoc(): void {
    window.open(this.dialogTitleHelpLink, '_blank')
  }

  toggleImportSettingPopup(open: boolean): void {
    this.showImportSettings = open
  }

  translateWithUnit(column: string): string {
    const unit = this.importUnit?.[column]
    return (
      this.translate.instant(this.headerTranslationPrefix + column) +
      (unit ? ` (${unit})` : '')
    )
  }

  get getErrors(): string[] {
    return [...this.errors, ...this.internalErrors]
  }
}
