import { convertToNewState, parseObj } from './migrationUtils'

export type Migration = {
  localStoreVersion: number
  description: string
  migrationFn: (localStore: Storage) => void
}

export const migrations: Migration[] = [
  {
    localStoreVersion: 1,
    description:
      'task of migration of local storage to reflect changes made in commit ef9c9834e544d58f2a97bfe9c5acd92533f0a800 : refactor(redux): add (a)sync actions+merge object&loadable+rmv ngrx/entity',
    migrationFn: localStore => {
      Object.keys(localStore).forEach(k => {
        const item = parseObj(localStore.getItem(k) ?? '')
        if (item !== false) {
          const newItem = convertToNewState(item)
          if (newItem !== false) {
            localStore.setItem(k, newItem)
          }
        }
      })
    },
  },
  {
    localStoreVersion: 2,
    description: 'Remove old key JobsitesList-favoriteIds',
    migrationFn: localStore => {
      localStore.removeItem('JobsitesList-favoriteIds')
    },
  },
  {
    localStoreVersion: 3,
    description: 'remove all key end with -tuto, ticket :feat(SSD-1256)',
    migrationFn: localStore => {
      Object.keys(localStore)
        .filter(key => key.endsWith('-tuto'))
        .forEach(key => {
          localStore.removeItem(key)
        })
    },
  },
  {
    localStoreVersion: 4,
    description: 'remove all old key start with productivity-',
    migrationFn: localStore => {
      Object.keys(localStore)
        .filter(key => key.startsWith('productivity-PILES'))
        .forEach(key => {
          localStore.removeItem(key)
        })
    },
  },
]
