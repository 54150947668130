import { createSelector } from '@ngrx/store'
import { EntitiesSelectorFactory } from 'app/shared/utils/redux/entities/entities.selectors'
import { getJobsiteId } from '../../../../core/store/router/router.selectors'
import { AggregationType } from '../../../../shared/constants/aggregation-type.enum'
import { ColumnDto } from '../../../../shared/remote-services/dtos/column.dto'
import { MinMax } from '../../../../shared/utils/MinMax'
import { ParameterModel } from '../../models/parameter.model'
import { getStats3dState } from '../columns-stats-3d/columns-stats-3d.selectors'
import {
  columnProdSelectors,
  getColumns,
  getDateRangeFilter,
  getParameter,
  metricsFiltersSelectors,
  nameFiltersSelectors,
} from '../jobsite-summary.selectors'
import { getState, JobsiteSummaryState, State } from '../state'
import { ColumnsCoordinatesStats } from '../../models/stats.model'
import { getCurrentColorRangeConfig } from '../column-color-range-config/column-color-range-selectors'
import { ColorRangeConfig } from '../../models/color-range-config.model'
import { getMapSceneType } from '../map-settings/map-settings.selectors'
import {
  getJobsiteRelocateColumns,
  getJobsiteRelocateStep,
} from '../../components/jobsite-relocate/store/jobsite-relocate.selectors'

export const getStatsState = createSelector(
  getState,
  (state: JobsiteSummaryState) => state.columnsStats,
)

const statsSelectors = new EntitiesSelectorFactory(
  getState,
  state => state.columnsStats,
)

export const getColumnsWithStats = createSelector(
  getJobsiteId,
  statsSelectors.getEntities,
  getStats3dState,
  getColumns.getAll,
  (jobsiteId, entities, stats3d, columns) => {
    const result: ColumnDto[] =
      columns?.map(column => ({
        ...column, // column is not extensible at runtime need to clone it
        stats: entities?.[column.key.id]?.stats[jobsiteId] ?? {},
        stats3d: stats3d?.value?.[column.key.id]?.stats[jobsiteId] ?? {},
      })) ?? []
    return result
  },
)

export const getColumnsForMap = createSelector(
  getColumnsWithStats,
  getJobsiteRelocateColumns,
  getJobsiteRelocateStep,
  (columnsWithState, relocatedColumns, step) => {
    return step === 1 ? relocatedColumns : columnsWithState
  },
)

export const getParametersLoaded = createSelector(
  getStatsState,
  state => state.loadedMetrics,
)

export const getFilteredColumnIds = createSelector(
  getColumnsWithStats,
  nameFiltersSelectors.getAll,
  metricsFiltersSelectors.getAll,
  getDateRangeFilter,
  columnProdSelectors.getValue,
  (columns, nameFilters, metricFilters, dateRangeFilter, prodData) => {
    if (columns) {
      let filteredColumns = columns

      if (nameFilters && nameFilters.length > 0) {
        filteredColumns = filteredColumns.filter(column => {
          return nameFilters.some(nameFilter => {
            if (column.key.name) {
              return column.key.name
                .toLowerCase()
                .includes(nameFilter.toLowerCase())
            }
            return false
          })
        })
      }

      if (metricFilters && metricFilters.length > 0) {
        filteredColumns = filteredColumns.filter(column => {
          return metricFilters.every(metricFilter => {
            const metricValue =
              column.stats[metricFilter.field.id] !== undefined &&
              column.stats[metricFilter.field.id][AggregationType.MAX] !==
                undefined
                ? column.stats[metricFilter.field.id][AggregationType.MAX]
                : undefined
            return (
              metricValue !== undefined &&
              metricValue >= metricFilter.min &&
              metricValue <= metricFilter.max
            )
          })
        })
      }

      if (
        dateRangeFilter &&
        (dateRangeFilter.startDate || dateRangeFilter.endDate)
      ) {
        filteredColumns = filteredColumns.filter(column => {
          const endDate = prodData[column.key.id]?.endDate
          if (endDate) {
            const date = new Date(endDate)
            date.setHours(0, 0, 0, 0)
            if (dateRangeFilter.startDate && dateRangeFilter.endDate) {
              return (
                date >= dateRangeFilter.startDate.toDate() &&
                date <= dateRangeFilter.endDate.toDate()
              )
            } else if (dateRangeFilter.startDate) {
              return date >= dateRangeFilter.startDate.toDate()
            } else {
              return date <= dateRangeFilter.endDate.toDate()
            }
          } else {
            return false
          }
        })
      }

      return filteredColumns.map(column => column.key.id)
    }
    return []
  },
)

export const getMinAndMaxSelectedMetric = (autoValue = false) =>
  createSelector<
    State,
    [ParameterModel, string[], ColumnDto[], ColorRangeConfig],
    MinMax
  >(
    getParameter,
    getFilteredColumnIds,
    getColumnsWithStats,
    getCurrentColorRangeConfig,
    (
      parameter: ParameterModel,
      filteredColumnIds,
      columms: ColumnDto[],
      colorRangeConfig: ColorRangeConfig,
    ) => {
      const minMax = new MinMax()
      if (parameter && parameter.metric) {
        if (autoValue || !colorRangeConfig || colorRangeConfig?.isAuto) {
          columms
            .filter(column => filteredColumnIds.includes(column.key.id))
            .filter(column => !!column.stats[parameter.metric])
            .forEach(column =>
              minMax.handleValue(
                column.stats[parameter.metric][parameter.operator],
              ),
            )
        } else if (
          colorRangeConfig?.isAuto === false &&
          colorRangeConfig?.customRange
        ) {
          minMax.setValue(colorRangeConfig.customRange)
        }
      }

      return minMax
    },
  )

export const get3dMinMaxSelectedMetric = (autoValue = false) =>
  createSelector(
    getParameter,
    getFilteredColumnIds,
    getColumnsWithStats,
    getCurrentColorRangeConfig,
    (
      parameter: ParameterModel,
      filteredColumnIds,
      columns: ColumnDto[],
      colorRangeConfig: ColorRangeConfig,
    ) => {
      const minMax = new MinMax()
      if (parameter && parameter.metric) {
        if (autoValue || !colorRangeConfig || colorRangeConfig?.isAuto) {
          columns
            .filter(column => filteredColumnIds.includes(column.key.id))
            .filter(
              column =>
                !!column.stats3d[parameter.metric] &&
                !!column.stats3d[parameter.metric][parameter.operator],
            )
            .forEach(column => {
              const paraValues = column.stats3d[parameter.metric][
                parameter.operator
              ].map(item => item.value)
              paraValues.forEach(val => {
                minMax.handleValue(val)
              })
            })
        } else if (
          colorRangeConfig?.isAuto === false &&
          colorRangeConfig?.customRange
        ) {
          minMax.setValue(colorRangeConfig.customRange)
        }
      }
      return minMax
    },
  )

export const getAutoMinMax = createSelector(
  getMinAndMaxSelectedMetric(true),
  get3dMinMaxSelectedMetric(true),
  getMapSceneType,
  (minMax2D, minMax3D, sceneType) => (sceneType === '3D' ? minMax3D : minMax2D),
)

export const getColumnsCoordinatesStats = createSelector(
  getColumnsWithStats,
  columns => {
    const nbColumnsWithCoordinates = columns.filter(
      column =>
        column.values?.longitude != null && column.values?.latitude != null,
    ).length

    return {
      nbColumns: columns.length,
      nbColumnsWithCoordinates,
      nbColumnsWithoutCoordinates: columns.length - nbColumnsWithCoordinates,
    } as ColumnsCoordinatesStats
  },
)

export const getNotEnoughCoordinates = createSelector(
  getColumnsCoordinatesStats,
  stats => stats.nbColumns > 1 && stats.nbColumnsWithCoordinates < 2,
)
