import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'
import { HFDeviationExportParams } from '../../../../jobsites-management/jobsite-summary/components/export-pdf-dialog/export-pdf-dialog.model'
import { ContractualTolerances } from '../../../models/contractual-tolerances.model'
import { MaxDepthDeviationReport } from '../../../models/max-depth-deviation-report.model'

@Component({
  selector: 'export-pdf-deviation-option',
  template: `
    <pdf-export-options-layout
      [title]="
        'JOBSITE_MANAGEMENT.VISUALIZATION.DEVIATION_REPORT.OPTIONS_TITLE'
          | translate
      "
      [disableSubmit]="value == null"
      (submitEvent)="submit()"
      (cancelEvent)="cancelEvent.emit()"
    >
      <export-pdf-deviation-option-form
        mat-dialog-content
        [contractualTolerances]="jobsiteContractualTolerances"
        [maxDepthDeviationReport]="maxDepthDeviationReport"
        (valueChange)="setValue($event)"
      ></export-pdf-deviation-option-form>
    </pdf-export-options-layout>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportPdfDeviationOptionsComponent {
  @Input() jobsiteContractualTolerances: ContractualTolerances | null
  @Input() maxDepthDeviationReport: MaxDepthDeviationReport | null

  @Output() submitEvent = new EventEmitter<HFDeviationExportParams>()
  @Output() cancelEvent = new EventEmitter<void>()

  value: HFDeviationExportParams | null = null

  submit(): void {
    if (this.value) {
      this.submitEvent.emit(this.value)
    }
  }

  setValue(event: HFDeviationExportParams | null): void {
    this.value = event
  }
}
