import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core'

@Component({
  selector: 'pdf-export-options-layout',
  template: `
    <h2 mat-dialog-title>{{ title }}</h2>
    <ng-content> </ng-content>
    <div mat-dialog-actions align="end">
      <button
        style="font-size: 14px"
        mat-raised-button
        type="button"
        (click)="cancelEvent.emit()"
      >
        {{ 'GENERAL.CANCEL' | translate }}
      </button>
      <button
        style="font-size: 14px"
        mat-raised-button
        color="primary"
        (click)="submitEvent.emit()"
        [disabled]="disableSubmit"
      >
        {{ 'GENERAL.VALIDATE' | translate }}
      </button>
    </div>
  `,
  styles: [
    `
      h2 {
        margin-bottom: 15px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfExportOptionsLayoutComponent {
  @Input() title: string
  @Input() disableSubmit = false
  @Output() submitEvent = new EventEmitter<void>()
  @Output() cancelEvent = new EventEmitter<void>()
}
