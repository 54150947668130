import { ChangeDetectionStrategy, Component } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { State } from '../../../store/state'
import { userPreferencesSelector } from '../../../../../core/store/user'
import { getUnitByField } from '../../../../../core/store/fields/fields.selectors'
import { Metric } from '../../../../../shared/constants/metric.enum'
import { ProgressType } from '../../../models/progress/progress-type.enum'
import { MatDialogRef } from '@angular/material/dialog'
import {
  ProgressionBatchImportDto,
  ProgressDto,
  ProgressUpdatePayload,
} from '../../../models/progress/progress.model'
import {
  getJobsiteId,
  getTechnique,
} from '../../../../../core/store/router/router.selectors'
import {
  getPlannedProgress,
  getProgressCategory,
} from '../../../../../core/store/jobsite-progress/jobsite-progress.selectors'
import { JobsiteProgressActions } from '../../../../../core/store/jobsite-progress/jobsite-progress.actions'
import { getJobsiteCSVPreference } from '../../../../../core/store/jobsite'
import { map } from 'rxjs/operators'
import { CsvPreferencesDto } from '../../../../../core/models/companyCsvPreferencesInput.dto'

@Component({
  selector: 'app-jobsite-productivity-planned-progress-dialog-container',
  template: `
    <app-jobsite-chart-edit-planned
      [plannedProgress]="plannedProgress$ | async"
      [progressType]="progressTypes"
      [userPreferences]="userPreferences$ | async"
      [depthUnits]="depthUnit$ | async"
      [progressCategory]="progressCategory$ | async"
      [technique]="technique$ | async"
      [jobsiteId]="jobsiteId$ | async"
      [csvSeparator]="csvSeparator$ | async"
      (closeEvent)="cancel()"
      (addPlannedProgress)="addPlannedProgress($event)"
      (editPlannedProgress)="editPlannedProgress($event)"
      (deletePlannedProgress)="deletePlannedProgress($event.id)"
      (plannedProgressBatch)="plannedProgressBatch($event)"
    ></app-jobsite-chart-edit-planned>
  `,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsiteProductivityPlannedProgressDialogContainer {
  constructor(
    public dialogRef: MatDialogRef<null, null>,
    private store: Store<State>,
  ) {}

  public plannedProgress$ = this.store.pipe(select(getPlannedProgress))
  public userPreferences$ = this.store.select(userPreferencesSelector.getValue)
  public depthUnit$ = this.store.pipe(select(getUnitByField(Metric.Depth)))
  public progressCategory$ = this.store.pipe(select(getProgressCategory))
  public technique$ = this.store.pipe(select(getTechnique))
  public jobsiteId$ = this.store.pipe(select(getJobsiteId))
  public csvSeparator$ = this.store.pipe(
    select(getJobsiteCSVPreference),
    map((preferences: CsvPreferencesDto) => preferences.csvSeparator),
  )

  public progressTypes = [ProgressType.PLANNED, ProgressType.PLANNED_CUSTOMER]

  cancel(): void {
    this.dialogRef.close(null)
  }

  addPlannedProgress($event: ProgressDto) {
    this.store.dispatch(
      JobsiteProgressActions.addPlannedProgress.createStart($event),
    )
  }

  editPlannedProgress($event: ProgressUpdatePayload) {
    this.store.dispatch(
      JobsiteProgressActions.editPlannedProgress.createStart($event.new),
    )
  }

  deletePlannedProgress($event: string) {
    this.store.dispatch(
      JobsiteProgressActions.deletePlannedProgress.createStart($event),
    )
  }

  plannedProgressBatch($event: ProgressionBatchImportDto) {
    this.store.dispatch(
      JobsiteProgressActions.plannedProgressBatch.createStart($event),
    )
  }
}
