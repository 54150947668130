import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { notEmpty } from 'app/shared/utils/notEmpty'
import { onCreate, onStart } from 'app/shared/utils/redux/operators'
import {
  concatMap,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { getJobsiteId, getTechnique } from '../router/router.selectors'
import { AsyncEffects } from '../../../shared/utils/redux/async.effects'
import {
  doneColumnIdsSelectors,
  notDiscardedDoneColumnIdsSelectors,
} from '../../../jobsites-management/jobsite-summary/store/jobsite-summary.selectors'
import { ProgressService } from '../../services/progress.service'
import { JobsiteProgressActions } from './jobsite-progress.actions'
import { State } from '../state'
import { JobsiteProductivityPlannedProgressDialogContainer } from '../../../jobsites-management/jobsite-summary/jobsite-productivity/common/components/jobsite-productivity-planned-progress-dialog-container'
import { MatDialog } from '@angular/material/dialog'

@Injectable()
export class JobsiteProgressEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private effectFactory: AsyncEffects,
    private progressService: ProgressService,
    private dialog: MatDialog,
  ) {}

  loadProgressData$ = createEffect(() =>
    this.effectFactory.asyncEffect(JobsiteProgressActions.getProgressData, () =>
      this.store.pipe(
        select(doneColumnIdsSelectors.getLoaded),
        filter(loaded => !!loaded),
        take(1),
        withLatestFrom(
          this.store.pipe(select(getTechnique), filter(notEmpty)),
          this.store.pipe(select(getJobsiteId), filter(notEmpty)),
          this.store.pipe(select(notDiscardedDoneColumnIdsSelectors)),
        ),
        switchMap(([ignored, technique, jobsiteId, columnDoneIds]) =>
          this.progressService.getProgression(
            jobsiteId,
            technique,
            columnDoneIds,
          ),
        ),
      ),
    ),
  )

  deletePlannedProgress$ = createEffect(() =>
    this.actions$.pipe(
      onStart(JobsiteProgressActions.deletePlannedProgress),
      map(a => a.payload),
      withLatestFrom(this.store.select(getTechnique)),
      switchMap(([id, technique]) =>
        this.progressService.deletePlannedProgress(technique, id),
      ),
      map(_ => JobsiteProgressActions.getProgressData.createStart()),
    ),
  )

  addPlannedProgress$ = createEffect(() =>
    this.actions$.pipe(
      onStart(JobsiteProgressActions.addPlannedProgress),
      map(a => a.payload),
      withLatestFrom(
        this.store.select(getTechnique),
        this.store.select(getJobsiteId),
      ),
      switchMap(([progress, technique, jobsiteId]) =>
        this.progressService.addPlannedProgress(technique, jobsiteId, progress),
      ),
      map(_ => JobsiteProgressActions.getProgressData.createStart()),
    ),
  )

  editPlannedProgress$ = createEffect(() =>
    this.actions$.pipe(
      onStart(JobsiteProgressActions.editPlannedProgress),
      map(a => a.payload),
      withLatestFrom(
        this.store.select(getTechnique),
        this.store.select(getJobsiteId),
      ),
      switchMap(([progress, technique, jobsiteId]) =>
        this.progressService.updatePlannedProgress(
          technique,
          jobsiteId,
          progress,
        ),
      ),
      map(_ => JobsiteProgressActions.getProgressData.createStart()),
    ),
  )

  batchPlannedProgress$ = createEffect(() =>
    this.actions$.pipe(
      onStart(JobsiteProgressActions.plannedProgressBatch),
      map(a => a.payload),
      withLatestFrom(
        this.store.select(getTechnique),
        this.store.select(getJobsiteId),
      ),
      switchMap(([progressBatchImportDto, technique, jobsiteId]) =>
        this.progressService.batchSavePlannedProgression(
          jobsiteId,
          technique,
          progressBatchImportDto.progress,
          progressBatchImportDto.type,
          progressBatchImportDto.category,
        ),
      ),
      map(_ => JobsiteProgressActions.getProgressData.createStart()),
    ),
  )

  updateProgressCategory$ = createEffect(() =>
    this.actions$.pipe(
      onStart(JobsiteProgressActions.updateProgressCategory),
      map(action => action.payload),
      switchMap(payload =>
        this.store.pipe(
          select(getTechnique),
          filter(notEmpty),
          take(1),
          withLatestFrom(
            this.store.pipe(select(getJobsiteId, filter(notEmpty))),
          ),
          switchMap(([technique, jobsiteId]) =>
            this.progressService.updateProgressCategory(
              jobsiteId,
              technique,
              payload,
            ),
          ),
        ),
      ),
      concatMap(res => [
        JobsiteProgressActions.updateProgressCategory.createComplete(res),
        JobsiteProgressActions.getProgressData.createStart(),
      ]),
    ),
  )

  showEditPlanedModal$ = createEffect(
    () =>
      this.actions$.pipe(
        onCreate(JobsiteProgressActions.showEditPlanedModal),
        tap(_ => {
          this.dialog.open(JobsiteProductivityPlannedProgressDialogContainer, {
            restoreFocus: false,
            autoFocus: false,
          })
        }),
      ),
    { dispatch: false },
  )
}
