import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import * as FileSaver from 'file-saver'
import { Observable } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import { FileService } from '../../core/services/file.service'

export const DownloaderGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Observable<UrlTree> => {
  const fileService = inject(FileService)
  const router = inject(Router)
  return fileService.downloadFileWithToken(route.params.token).pipe(
    tap(blob => FileSaver.saveAs(blob, `${route.params.token}`)),
    map(() => router.createUrlTree(['/'])),
  )
}
