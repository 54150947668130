import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'

import { JobsiteDevInfoDialogInput } from '../models/dev-info.model'
import { JobsiteStatusDto } from '../remote-services/dtos/jobsiteStatus.dto'
import { JobsiteActions } from '../../core/store/jobsite'
import { Store } from '@ngrx/store'
import { State } from '../../core/store'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'jobsite-dev-info-dialog',
  template: `
    <h3 mat-dialog-title>
      {{ 'JOBSITE_DEV_INFO.DIALOG_TITLE' | translate }}
    </h3>
    <div
      mat-dialog-content
      class="jobsite-dev-info-dialog-main flex-fill flex-layout-column"
    >
      <h4>
        {{ 'JOBSITE_DEV_INFO.ADMIN_ACTIONS' | translate }}
      </h4>
      <div>
        <button
          class="close-btn"
          style="font-size: 14px"
          mat-raised-button
          type="button"
          (click)="jobsiteReset()"
        >
          Reset jobsite
        </button>
        <button
          class="close-btn"
          style="font-size: 14px"
          mat-raised-button
          type="button"
          (click)="removeDuplicatedPileNames()"
        >
          Remove Duplicated piles names
        </button>
      </div>
      <h4>
        {{ 'JOBSITE_DEV_INFO.STATUS_HISTORY' | translate }}
      </h4>
      <jobsite-status-history
        class="flex-fill"
        [statuses]="getStatusHistory()"
      ></jobsite-status-history>
    </div>
    <div mat-dialog-actions align="center">
      <button
        class="close-btn"
        style="font-size: 14px"
        mat-raised-button
        type="button"
        (click)="close()"
      >
        {{ 'GENERAL.CLOSE' | translate }}
      </button>
    </div>
  `,
  styles: [
    `
      .jobsite-dev-info-dialog-main {
        gap: 10px;
      }

      .close-btn {
        font-size: 14px;
        margin-top: 10px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsiteDevInfoDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: JobsiteDevInfoDialogInput,
    private dialogRef: MatDialogRef<JobsiteDevInfoDialog>,
    private store: Store<State>,
  ) {}

  getStatusHistory(): JobsiteStatusDto[] {
    return (
      this.data?.jobsite?.techniques.find(t => t.name === this.data?.technique)
        ?.statuses ?? []
    )
  }

  close(): void {
    this.dialogRef.close(null)
  }

  jobsiteReset(): void {
    this.store.dispatch(JobsiteActions.resetJobsite.create(false))
  }

  removeDuplicatedPileNames(): void {
    this.store.dispatch(JobsiteActions.removeDuplicatedPileNames.create())
  }
}
