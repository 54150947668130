import { Injectable } from '@angular/core'
import { exhaustiveCheck } from '../utils/exhautisveCheck'
import { DateTimeFormatsDto } from '../remote-services/dtos/userPreferences.dto'
import { DateTimeFormatsSettings } from 'app/jobsites-management/settings/store/date-time-format/state'
import { DateSelectModel } from '../../jobsites-management/settings/models/date-time-format.model'
import {
  DateFormats,
  DateSeparators,
  TimeFormats,
} from '../models/date-time-format.model'

@Injectable()
export class DateTimeFormatService {
  getDateFormat(
    dateFormat: DateFormats,
    separator: string,
    backendPattern = false,
  ): string {
    let result: string
    const dayPattern = backendPattern ? 'dd' : 'DD'
    const yearPattern = backendPattern ? 'yyyy' : 'YYYY'

    switch (dateFormat) {
      case 'EN':
        result = `MM${separator}${dayPattern}${separator}${yearPattern}`
        break
      case 'FR':
        result = `${dayPattern}${separator}MM${separator}${yearPattern}`
        break
      case 'ISO':
        result = `${yearPattern}${separator}MM${separator}${dayPattern}`
        break
      default:
        exhaustiveCheck(dateFormat)
    }
    return result
  }

  getHighchartsDateFormat(dateFormat: DateFormats, separator: string): string {
    let result: string

    switch (dateFormat) {
      case 'EN':
        result = `%m${separator}%d${separator}%Y`
        break
      case 'FR':
        result = `%d${separator}%m${separator}%Y`
        break
      case 'ISO':
        result = `%Y${separator}%m${separator}%d`
        break
      default:
        exhaustiveCheck(dateFormat)
    }
    return result
  }

  getTimeFormat(timeFormat: TimeFormats, backendPattern = false): string {
    let result: string
    const amPmPattern = backendPattern ? 'a' : 'A'

    switch (timeFormat) {
      case '12':
        result = `hh:mm ${amPmPattern}`
        break
      case '24':
        result = 'HH:mm'
        break
      default:
        exhaustiveCheck(timeFormat)
    }
    return result
  }

  getDateTimeFormat(
    dateFormat: DateFormats,
    separator: DateSeparators,
    timeFormat: TimeFormats,
    dateTimeSeparated: boolean,
    dateTimeSeparator: string,
    dateTimeReversed: boolean,
    backendPattern = false,
  ): string {
    const date = this.getDateFormat(dateFormat, separator, backendPattern)
    const time = this.getTimeFormat(timeFormat, backendPattern)
    const atPattern = backendPattern
      ? `'${dateTimeSeparator}'`
      : `[${dateTimeSeparator}]`

    return `${dateTimeReversed ? time : date}${
      dateTimeSeparated ? atPattern : ' '
    }${dateTimeReversed ? date : time}`
  }

  parseBackendFormatsSettings(
    backendFormats: DateTimeFormatsDto,
  ): DateTimeFormatsSettings {
    const dateTimeSeparation = this.parseBackendDateTimeSeparation(
      backendFormats.dateTimeFormat,
    )
    const dateTimeReversed = this.parseBackendDateTimeReversed(
      backendFormats.dateTimeFormat,
    )
    const dateTimeFormat = this.parseBackendDateTimeFormats(
      backendFormats.dateTimeFormat,
      dateTimeSeparation.isSeparated,
      dateTimeReversed,
    )

    return {
      dateFormat: this.parseBackendDateFormat(backendFormats.dateFormat),
      timeFormat: this.parseBackendTime(backendFormats.timeFormat),
      dateTimeDateFormat: dateTimeFormat.date,
      dateTimeSeparated: dateTimeSeparation.isSeparated,
      dateTimeSeparator: dateTimeSeparation.separator,
      dateTimeTimeFormat: dateTimeFormat.time,
      dateTimeReversed,
    }
  }

  parseBackendFormats(formats: DateTimeFormatsDto): DateTimeFormatsDto {
    const parsedFormats = this.parseBackendFormatsSettings(formats)
    return {
      dateFormat: this.getDateFormat(
        parsedFormats.dateFormat.format,
        parsedFormats.dateFormat.separator,
      ),
      timeFormat: this.getTimeFormat(parsedFormats.timeFormat),
      dateTimeFormat: this.getDateTimeFormat(
        parsedFormats.dateTimeDateFormat.format,
        parsedFormats.dateTimeDateFormat.separator,
        parsedFormats.dateTimeTimeFormat,
        parsedFormats.dateTimeSeparated,
        parsedFormats.dateTimeSeparator,
        parsedFormats.dateTimeReversed,
      ),
      highChartsDateFormat: this.getHighchartsDateFormat(
        parsedFormats.dateFormat.format,
        parsedFormats.dateFormat.separator,
      ),
    }
  }

  private parseBackendDateFormat(format: string): DateSelectModel {
    const dayPatternIndex = format.indexOf('dd')
    return {
      format:
        dayPatternIndex === 0 ? 'FR' : dayPatternIndex === 3 ? 'EN' : 'ISO',
      separator: format.indexOf('/') === -1 ? '-' : '/',
    }
  }

  private parseBackendTime(format: string): TimeFormats {
    return format.indexOf('HH') === -1 ? '12' : '24'
  }

  private parseBackendDateTimeSeparation(format: string): {
    isSeparated: boolean
    separator: string
  } {
    const splitFormat = format.split("'")
    return {
      isSeparated: splitFormat.length === 3,
      separator: splitFormat.length === 3 ? splitFormat[1] : '',
    }
  }

  private parseBackendDateTimeReversed(format: string): boolean {
    return format.charAt(0).toLowerCase() === 'h'
  }

  private parseBackendDateTimeFormats(
    format: string,
    isSeparated: boolean,
    isReversed: boolean,
  ): {
    date: DateSelectModel
    time: TimeFormats
  } {
    const splitFormat = format.split(isSeparated ? "'" : ' ')
    if (isSeparated) {
      return {
        date: this.parseBackendDateFormat(
          isReversed ? splitFormat[2] : splitFormat[0],
        ),
        time: this.parseBackendTime(
          isReversed ? splitFormat[0] : splitFormat[2],
        ),
      }
    } else {
      return {
        date: this.parseBackendDateFormat(
          isReversed ? splitFormat[splitFormat.length - 1] : splitFormat[0],
        ),
        time: splitFormat.length === 3 ? '12' : '24',
      }
    }
  }
}
