import {
  HttpClient,
  HttpEventType,
  HttpParams,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { TechniqueNames } from 'app/shared/remote-services/dtos/technique.dto'
import { EMPTY, Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { FileModel } from '../../file-explorer/models/file-model.model'
import { generateUUID } from '../../shared/utils/uuidGenerator'
import { UploadEventModel } from '../../widgets/uploader-files/models/upload-event.model'

@Injectable()
export class FileService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  uploadFile(
    file: File,
    techniqueName: TechniqueNames,
    filePathPrefix = '/TODO/',
  ): Observable<UploadEventModel> {
    // File size greater than 1 Go
    if (file.size > 1000000000) {
      return throwError(this.translate.instant('WIDGET.UPLOAD.ERROR_TOOBIG'))
    }
    if (filePathPrefix.length > 0 && !filePathPrefix.endsWith('/')) {
      filePathPrefix += '/'
    }

    const formData = new FormData()
    formData.append('file', file)

    const params = new HttpParams()
      .set('directory', filePathPrefix)
      .set('filename', file.name)

    const uploadReq = new HttpRequest(
      'POST',
      `export/download/${techniqueName}/upload`,
      formData,
      {
        reportProgress: true,
        params,
      },
    )

    return this.http.request(uploadReq).pipe(
      map(event => {
        const result = { loaded: false, progress: 0 }

        if (event.type === HttpEventType.UploadProgress) {
          result.progress = Math.round(
            (100 * event.loaded) / (event.total || 0),
          )
        } else if (event.type === HttpEventType.Response) {
          result.loaded = true
          result.progress = 100
        }
        return result
      }),
      catchError(() => {
        throwError(this.translate.instant('WIDGET.UPLOAD.ERROR_GENERIC_RETRY'))
        return EMPTY
      }),
    )
  }

  getDetails(
    path: string,
    techniqueNameLowerCase: string,
  ): Observable<FileModel[]> {
    const params = new HttpParams().set('path', path)
    return this.http
      .get<FileModel[]>(`export/download/${techniqueNameLowerCase}/list`, {
        params,
      })
      .pipe(
        map(files =>
          files.map(file => ({
            ...file,
            id: generateUUID(),
            path: `${path}/${file.name}`,
          })),
        ),
      )
  }

  downloadFile(
    absolutePath: string,
    techniqueName: TechniqueNames,
  ): Observable<Blob> {
    const params = new HttpParams().set('path', absolutePath)
    return this.http
      .get(`export/download/${techniqueName}/download`, {
        params,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map(res => new Blob([res.body])))
  }

  downloadFileWithToken(token: string): Observable<Blob> {
    return this.http
      .get(`export/download/token/get/${token}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(map(res => new Blob([res.body])))
  }

  deleteFile(
    absolutePath: string,
    techniqueName: TechniqueNames,
  ): Observable<void> {
    const params = new HttpParams().set('path', absolutePath)
    return this.http.delete<void>(`export/download/${techniqueName}/delete`, {
      params,
    })
  }
}
