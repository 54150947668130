import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { TechniquesGuard } from './core/guards/techniques.guard'
import { PermissionsGuard } from './core/guards/permissions.guard'
import { UserPreferencesGuard } from './core/guards/user-preferences.guard'
import { NgModule } from '@angular/core'
import { DownloaderGuard } from './downloader/guards/downloader.guard'
import { CompaniesGuardFn } from './core/guards/companies.guard'
import { MobilePermissionsGuard } from './core/guards/mobile-permissions.guard'
import { PlatformDisruptionsGuard } from './core/guards/platform-disruptions.guard'
import { AnalyticsEventKeys } from './shared/constants/matomo/analytics-event-keys.enum'

const APP_ROUTE: Routes = [
  {
    path: 'm',
    canActivate: [
      MobilePermissionsGuard,
      UserPreferencesGuard,
      CompaniesGuardFn,
    ],
    loadChildren: () =>
      import('app/mobile/mobile.module').then(m => m.MobileModule),
  },
  {
    path: '',
    canActivate: [
      PlatformDisruptionsGuard,
      TechniquesGuard,
      UserPreferencesGuard,
      PermissionsGuard,
    ],
    canActivateChild: [PermissionsGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('app/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'analysis/:techniqueName',
        loadChildren: () =>
          import('app/piles/piles.module').then(m => m.PilesModule),
      },
      {
        path: 'jobsites-management',
        loadChildren: () =>
          import('app/jobsites-management/jobsites-management.module').then(
            m => m.JobsitesManagementModule,
          ),
      },
      {
        path: 'files-explorer/:techniqueName',
        loadChildren: () =>
          import('app/file-explorer/file-explorer.module').then(
            m => m.FileExplorerModule,
          ),
      },
      {
        path: 'download/:token',
        loadChildren: () =>
          import('app/home/home.module').then(m => m.HomeModule),
        canActivate: [DownloaderGuard],
        data: {
          analytics: {
            title: AnalyticsEventKeys.EXPORT_FILE_DOWNLOAD,
          },
        },
      },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTE, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
