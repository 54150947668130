import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router'
import { Selector, Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, concatMap, filter, switchMap, take } from 'rxjs/operators'
import { LoadableActionFactory } from './redux/loadable/loadable.actions'
import { inject } from '@angular/core'

export type GuardChecks<S> = {
  preCheck?: (store: Store<S>, router: Router) => Observable<boolean>
  postCheck?: (store: Store<S>, router: Router) => Observable<boolean | UrlTree>
}

export class AbstractLoadableGuardBuilder<S> {
  constructor(
    private selector: Selector<S, boolean>,
    private action: LoadableActionFactory<void, unknown>,
    private reset: boolean = false,
    private checks: GuardChecks<S> = {
      preCheck: (store: Store<S>, router: Router) => of(true),
      postCheck: (store: Store<S>, router: Router) => of(true),
    },
  ) {}

  canActivate(): CanActivateFn {
    return (
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
    ):
      | Observable<boolean | UrlTree>
      | Promise<boolean | UrlTree>
      | boolean
      | UrlTree => {
      const store = inject(Store<S>)
      const router = inject(Router)
      const checkStore = (): Observable<boolean> => {
        if (this.reset) {
          store.dispatch(this.action.createReset())
        }
        return store.select(this.selector).pipe(
          concatMap(loaded => {
            if (!loaded || this.reset) {
              store.dispatch(this.action.createStart())
            }
            return store.select(this.selector)
          }),
          filter(loaded => Boolean(loaded)),
          take(1),
          catchError((e: Error) => {
            console.error(e)
            return of(false)
          }),
        )
      }

      return (
        typeof this.checks?.preCheck === 'function'
          ? this.checks.preCheck(store, router)
          : of(true)
      ).pipe(
        switchMap(value => (value ? checkStore() : of(false))),
        switchMap(() =>
          typeof this.checks?.postCheck === 'function'
            ? this.checks.postCheck(store, router)
            : of(true),
        ),
        catchError(err => {
          console.error(err)
          return of(false)
        }),
      )
    }
  }
}
