import { Location } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  ViewChild,
} from '@angular/core'
import { MatMenuTrigger } from '@angular/material/menu'
import { Router, UrlTree } from '@angular/router'
import { KeycloakService } from 'app/keycloak/keycloak.service'
import { CompanyDto } from '../core/models/company.dto'
import {
  UnitSystem,
  UserLanguage,
  UserPreferencesDto,
} from '../shared/remote-services/dtos/userPreferences.dto'
import { DtoActiveDisruption } from '../core/models/PlatformDisruption.model'
import { environment } from '../../environments/environment'
import { AnalyticsService } from '../shared/service/analytics.service'
import { MatomoCustomDimensions } from '../shared/constants/matomo/matomo-custom-dimensions.enum'
import { ApplicationModel } from '../keycloak/models/user.model'
import { Applications } from '../keycloak/applications.enum'
import { TechniqueNames } from '../shared/remote-services/dtos/technique.dto'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnChanges {
  headerLogoLink: string
  newTutoIsRead: number
  hideOverlay = true
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger

  public servers: {
    name: string
    gitBranch: string
    gitCommit: string
    available: boolean
    gitDate: string
  }[] = []

  closed = false
  displayBackButton = false

  @Input() loading: boolean
  @Input() selectedJobsiteContractNumber: string
  @Input() userPreferences: UserPreferencesDto
  @Input() backUrl: string | UrlTree
  @Input() username: string
  @Input() userId: string
  @Input() company: string
  @Input() isUploading: boolean
  @Input() currentUrl: string
  @Input() jobsiteConfigurable: boolean
  @Input() jobsiteCompany: CompanyDto
  @Input() jobsiteName: string
  @Input() message: DtoActiveDisruption
  @Input() applications: ApplicationModel[]
  @Input() technique: TechniqueNames

  @Output() changeLanguageEvent: EventEmitter<UserPreferencesDto> =
    new EventEmitter()
  @Output() changeUnitSystemEvent: EventEmitter<UserPreferencesDto> =
    new EventEmitter()
  @Output() unloadPageEvent = new EventEmitter<void>()
  @Output() goToSettingsEvent = new EventEmitter<string | null>()
  @Output() goToAsBuiltTemplateEvent = new EventEmitter<string | null>()
  @Output() changeTutoIsReadEvent: EventEmitter<UserPreferencesDto> =
    new EventEmitter()

  @HostListener('window:beforeunload', ['$event'])
  uploading($event: any): void {
    if (this.isUploading) {
      this.unloadPageEvent.emit()
      $event.returnValue = true
    }
  }

  constructor(
    private keycloakService: KeycloakService,
    private router: Router,
    private location: Location,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes.company && this.company) {
      if (this.company === 'BSL') {
        this.headerLogoLink = '/assets/logos/bsl.png'
      } else if (this.company === 'WP') {
        this.headerLogoLink = '/assets/logos/wp.png'
      } else if (this.company === 'SBFS') {
        this.headerLogoLink = '/assets/logos/sbfs.svg'
      } else if (this.company === 'RBL') {
        this.headerLogoLink = '/assets/logos/rbl.png'
      } else if (this.company === 'RK') {
        this.headerLogoLink = '/assets/logos/rk.png'
      } else if (this.company === 'CIMESA') {
        this.headerLogoLink = '/assets/logos/cimesa.png'
      } else {
        this.headerLogoLink = '/assets/logos/sb.png'
      }
    }

    if (
      changes &&
      changes.userPreferences &&
      changes.userPreferences.previousValue &&
      changes.userPreferences.currentValue
    ) {
      this.checkTutoIsReadValue()
    }

    if (changes.currentUrl?.currentValue?.includes('asbuilt')) {
      this.goToAsBuiltTemplateEvent.emit(changes.currentUrl?.previousValue)
    }

    if (changes.currentUrl?.currentValue != null) {
      const jobsiteManagementApps: ApplicationModel[] =
        this.applications.filter(
          (app: ApplicationModel): boolean =>
            app.name === Applications.JOBSITE_MANAGEMENT.keycloakName,
        )

      this.displayBackButton = !(
        this.currentUrl === '/' ||
        (this.currentUrl ===
          `/${Applications.JOBSITE_MANAGEMENT.url}/${this.technique}` &&
          this.applications.length === 1 &&
          jobsiteManagementApps.length > 0 &&
          jobsiteManagementApps[0].techniques?.length === 1)
      )
    }
  }

  selectLanguage(lang: UserLanguage): void {
    this.analyticsService.sendActionAnalytics(
      'User Preferences',
      'Switch languages',
      `Change the language of the application`,
      {
        [MatomoCustomDimensions.ACTION_DATA]: lang,
        [MatomoCustomDimensions.ACTION_DATA_DESCRIPTION]: 'Language',
      },
    )
    this.changeLanguageEvent.emit({ ...this.userPreferences, language: lang })
  }

  setUnitSystem(unitSystem: UnitSystem): void {
    this.analyticsService.sendActionAnalytics(
      'User Preferences',
      'Switch measurement units',
      `Change the unit of measurement in the application`,
      {
        [MatomoCustomDimensions.ACTION_DATA]: unitSystem,
        [MatomoCustomDimensions.ACTION_DATA_DESCRIPTION]: 'Unit System',
      },
    )
    this.changeUnitSystemEvent.emit({ ...this.userPreferences, unitSystem })
  }

  back(): void {
    if (this.backUrl) {
      this.router.navigateByUrl(this.backUrl)
    } else {
      this.location.back()
    }
  }

  logout(): void {
    this.router.navigate(['/']).then(() => this.keycloakService.logout())
  }

  goToSettings(): void {
    const settingsRoute = '/jobsites-management/settings/'
    this.goToSettingsEvent.emit(
      !this.location.path().includes(settingsRoute)
        ? this.location.path()
        : null,
    )
    this.router.navigate([settingsRoute])
  }

  goToHelp(): void {
    this.analyticsService.sendActionAnalytics(
      'User Preferences',
      'Online help',
      `Click help button`,
    )
    window.open('https://support-ild.atlassian.net/wiki/spaces/ZHELP/overview')
  }

  onCheck(checked: boolean): void {
    this.newTutoIsRead = checked
      ? environment.showDialogTuto
      : this.userPreferences.tutoIsRead
  }

  hide(): void {
    if (this.userPreferences.tutoIsRead !== this.newTutoIsRead) {
      this.changeTutoIsReadEvent.emit({
        ...this.userPreferences,
        tutoIsRead: this.newTutoIsRead,
      })
    }
    this.hideOverlay = true
    this.menuTrigger?.closeMenu()
  }

  togglePlatformDisruptionPopup(): void {
    this.closed = !this.closed
  }

  updatePlatformDisruptionPopup(value: boolean): void {
    this.closed = value
  }

  private checkTutoIsReadValue(): void {
    if (this.userPreferences?.tutoIsRead < environment.showDialogTuto) {
      this.newTutoIsRead = environment.showDialogTuto
      this.hideOverlay = false
      this.menuTrigger?.openMenu()
    }
  }
}
