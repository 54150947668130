import { InjectionToken } from '@angular/core'
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  MetaReducer,
} from '@ngrx/store'
import { TechniquesActions } from 'app/core/store/techniques/techniques.actions'
import { LoadableState } from 'app/shared/utils/redux/loadable/loadable.state'
import * as core from '../../../core/store'
import { ColumnDto } from '../../../shared/remote-services/dtos/column.dto'
import { createEntitiesReducer } from '../../../shared/utils/redux/entities/entities.reducer'
import { EntitiesState } from '../../../shared/utils/redux/entities/entities.state'
import { createListReducer } from '../../../shared/utils/redux/list/list.reducer'
import { ListState } from '../../../shared/utils/redux/list/list.state'
import { createLoadableReducer } from '../../../shared/utils/redux/loadable/loadable.reducer'
import { createValueReducer } from '../../../shared/utils/redux/utils.reducers'
import { JobsiteSummaryActions } from '../../jobsite-summary/store/jobsite-summary.actions'
import { ChartDto } from '../models/chart/chart.dto'
import { JsonTimeSeriesHeader } from '../models/jsonTimeSeriesHeader.model'
import { RecordDto } from '../models/record.dto'
import { ChartOrTemplateDto } from '../models/template/chartOrTemplate.dto'
import { TemplateConfigDto } from '../models/template/templateConfig.dto'
import { ColumnVisualizationActions } from './column-visualization.actions'
import { TemplatesActions } from './templates/templates.actions'
import { TimeseriesActions } from './timeseries/timeseries.actions'
import * as wipTemplate from './wipTemplate/wipTemplate.reducer'
import { UUID } from '../../../shared/models/utils.type'
import { OptionsWithError } from './column-visualization.model'
import { OptionsWithId } from '../models/highChartOptions.model'
import { WithError } from 'app/jobsites-management/jobsite-summary/models/data-science/with-error.model'

export interface State extends core.State {
  columnVisualization: ColumnVisualizationState
}

export interface ColumnVisualizationState {
  column: LoadableState<ColumnDto | null>
  header: LoadableState<JsonTimeSeriesHeader | null>
  templates: EntitiesState<TemplateConfigDto>
  companyTemplateId: LoadableState<string | null>
  jobsiteTemplateId: LoadableState<string | null>
  userTemplateId: LoadableState<string | null>
  defaultTemplteId: LoadableState<string | null>
  charts: EntitiesState<ChartDto>
  highchartsOptions: EntitiesState<OptionsWithError>
  wipTemplate: TemplateConfigDto
  selectedDto: ChartOrTemplateDto | undefined
  selectedHeaders: ListState<string> | undefined
  records: EntitiesState<RecordDto>
  templatesLinkedToJobsite: LoadableState<UUID[]> | undefined
}

export const reducers: ActionReducerMap<ColumnVisualizationState> = {
  column: createLoadableReducer(ColumnVisualizationActions.loadColumn, null),
  header: createLoadableReducer(TimeseriesActions.loadHeader, null),
  templates: createEntitiesReducer(TemplatesActions.loadTemplates),
  companyTemplateId: createLoadableReducer(
    ColumnVisualizationActions.loadCompanyTemplate,
    null,
  ),
  jobsiteTemplateId: createLoadableReducer(
    ColumnVisualizationActions.loadJobsiteTemplateId,
    null,
  ),
  userTemplateId: createLoadableReducer(
    ColumnVisualizationActions.loadUserTemplateId,
    null,
  ),

  templatesLinkedToJobsite: createLoadableReducer(
    TemplatesActions.checkTemplatesLinkedToJobsite,
    null,
  ),

  defaultTemplteId: createLoadableReducer(
    ColumnVisualizationActions.loadDefaultTemplateId,
    null,
  ),

  charts: createEntitiesReducer(ColumnVisualizationActions.loadCharts),
  highchartsOptions: createEntitiesReducer(
    ColumnVisualizationActions.loadHighchartsOptions,
    (item: WithError<OptionsWithId>) => item.value.id,
  ),
  wipTemplate: wipTemplate.reducers,
  selectedDto: createValueReducer(
    undefined,
    ColumnVisualizationActions.setSelectDto,
  ),
  selectedHeaders: createListReducer(
    ColumnVisualizationActions.selectedHeaders,
  ),
  records: createEntitiesReducer(ColumnVisualizationActions.records),
}

export const getState = createFeatureSelector<ColumnVisualizationState>(
  'columnVisualization',
)

export const reducerInjecteur = new InjectionToken(
  'Column Visualization Registered Reducers',
)

function getReducers(): ActionReducerMap<ColumnVisualizationState> {
  return reducers
}

export const reducerProvider = [
  { provide: reducerInjecteur, useFactory: getReducers },
]

export function clearState(
  reducer: ActionReducer<ColumnVisualizationState>,
): ActionReducer<ColumnVisualizationState> {
  return (
    state: ColumnVisualizationState,
    action: Action,
  ): ColumnVisualizationState => {
    if (action.type === JobsiteSummaryActions.clickOnColumn.CREATE) {
      state = {
        ...state,
        highchartsOptions: {
          loaded: false,
          loading: false,
          value: {},
        },
        header: null,
        column: null,
        selectedDto: undefined,
        selectedHeaders: {
          loaded: false,
          loading: false,
          value: [],
        },
        records: {
          loaded: false,
          loading: false,
          value: {},
        },
      }
    } else if (action.type === TechniquesActions.selectApplication.CREATE) {
      state = {
        ...state,
        templates: {
          loaded: false,
          loading: false,
          value: {},
        },
        charts: {
          loaded: false,
          loading: false,
          value: {},
        },
      }
    }
    return reducer(state, action)
  }
}
export const metaReducers: Array<MetaReducer<any, any>> = [clearState]
