import { map } from 'rxjs/operators'
import { AbstractLoadableGuardBuilder } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../store'
import { getLoaded, UserActions } from '../store/user'
import { CanActivateFn } from '@angular/router'

export const MobilePermissionsGuard: CanActivateFn =
  new AbstractLoadableGuardBuilder<State>(
    getLoaded,
    UserActions.loadUser,
    false,
    {
      postCheck: store =>
        store.pipe(
          map(() => {
            return true
          }),
        ),
    },
  ).canActivate()
