import { AbstractLoadableGuardBuilder } from '../../shared/utils/abstract-loadable.guard'
import { State } from '../store'
import { TechniquesActions } from '../store/techniques/techniques.actions'
import { techniqueSelectors } from '../store/techniques/techniques.selectors'
import { CanActivateFn } from '@angular/router'

export const TechniquesGuard: CanActivateFn =
  new AbstractLoadableGuardBuilder<State>(
    techniqueSelectors.getLoaded,
    TechniquesActions.loadTechniques,
  ).canActivate()
