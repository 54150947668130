/*
 * Generated type guards for "stats.dto.ts".
 * WARNING: Do not manually change this file.
 */
import {
  StatsDto,
  ParaValuesVersusDepth,
  Stats3DDto,
  StatsType,
  StatsOverallRequest,
  StatsOverallSummary,
  LoadColumnsStatsPayload,
} from './stats.dto'
import { isParameterModel } from './parameter.model.type.guard'

export function isStatsDto(obj: unknown): obj is StatsDto {
  const typedObj = obj as StatsDto
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    ((typedObj['stats'] !== null && typeof typedObj['stats'] === 'object') ||
      typeof typedObj['stats'] === 'function') &&
    Object.entries<any>(typedObj['stats']).every(
      ([key, value]) => typeof value === 'number' && typeof key === 'string',
    )
  )
}

export function isParaValuesVersusDepth(
  obj: unknown,
): obj is ParaValuesVersusDepth {
  const typedObj = obj as ParaValuesVersusDepth
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['start'] === 'number' &&
    typeof typedObj['end'] === 'number' &&
    typeof typedObj['value'] === 'number'
  )
}

export function isStats3DDto(obj: unknown): obj is Stats3DDto {
  const typedObj = obj as Stats3DDto
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['id'] === 'string' &&
    ((typedObj['stats'] !== null && typeof typedObj['stats'] === 'object') ||
      typeof typedObj['stats'] === 'function') &&
    Object.entries<any>(typedObj['stats']).every(
      ([key, value]) =>
        Array.isArray(value) &&
        value.every((e: any) => isParaValuesVersusDepth(e) as boolean) &&
        typeof key === 'string',
    )
  )
}

export function isStatsType(obj: unknown): obj is StatsType {
  const typedObj = obj as StatsType
  return (
    typedObj === 'MAX' ||
    typedObj === 'MIN' ||
    typedObj === 'SUM' ||
    typedObj === 'COUNT' ||
    typedObj === 'AVG' ||
    typedObj === 'SUM_MAX' ||
    typedObj === 'SUM_AVG' ||
    typedObj === 'AVG_AVG'
  )
}

export function isStatsOverallRequest(
  obj: unknown,
): obj is StatsOverallRequest {
  const typedObj = obj as StatsOverallRequest
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['fieldId'] === 'string' &&
    Array.isArray(typedObj['operators']) &&
    typedObj['operators'].every((e: any) => isStatsType(e) as boolean)
  )
}

export function isStatsOverallSummary(
  obj: unknown,
): obj is StatsOverallSummary {
  const typedObj = obj as StatsOverallSummary
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    typeof typedObj['fieldId'] === 'string' &&
    typedObj['values'] instanceof Map
  )
}

export function isLoadColumnsStatsPayload(
  obj: unknown,
): obj is LoadColumnsStatsPayload {
  const typedObj = obj as LoadColumnsStatsPayload
  return (
    ((typedObj !== null && typeof typedObj === 'object') ||
      typeof typedObj === 'function') &&
    (isParameterModel(typedObj['parameter']) as boolean) &&
    typeof typedObj['jobsiteId'] === 'string' &&
    Array.isArray(typedObj['dtos']) &&
    typedObj['dtos'].every((e: any) => isStatsDto(e) as boolean)
  )
}
